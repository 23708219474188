import React, { Component } from 'react';
import history from './History'
import './Main.css'
import cashstoreLogo from './Images/cash-store-main.png'


let iframe;
let isValidaccount_number = false;
let currentDate = (new Date()).getMonth() + 1 + '/' + (new Date()).getDate() + '/' + (new Date()).getFullYear();

export class PayByPhoneCVVOnly extends Component {
    static displayName = PayByPhoneCVVOnly.name;

    constructor(props) {
        super(props);
        let url = process.env.REACT_APP_SUB_URL;
        this.state = {

            account_number: null,
            card_cvv: null,
            showTokenSuceess: false,
            showTokenFailed: false,
            showMain: true,
            showMailConfirmation: false,
            tokenExValue: null,
            isDisable: true,
            accountNumber: null,
            payAmount: 0,
            date: "",
            reference: "",
            name: "",
            last4OfCard: "",
            authCode: "",
            result: "",
            totalAmount: "",
            cardToken: "",
            email_id: "",
            contactId: 0,
            loanId: "",
            printUrl: "/TokenExIFrameConfig/PdfGen?",
            errors: {
                account_number: "",
                card_cvv: "",
                email_id: ""
            },
            suburl: url == undefined ? '' : url
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
    }

    handleCVVMask = event => {

        if (document.getElementById("toggleCVV").classList.contains('fa-eye-slash')) {
            document.getElementById("toggleCVV").classList.remove('fa-eye-slash');
            document.getElementById("toggleCVV").classList.add('fa-eye');
        }
        else {
            document.getElementById("toggleCVV").classList.remove('fa-eye');
            document.getElementById("toggleCVV").classList.add('fa-eye-slash');
        }

        iframe.toggleMask();
    }

    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case "account_number":
                if (value.length === 0) {
                    isValidaccount_number = false;
                }
                else {
                    isValidaccount_number = true;
                }
                if (isValidaccount_number) {
                    this.setState({ isDisable: false });
                }
                else {
                    this.setState({ isDisable: true });
                }
                errors.account_number =
                    value.length === 0 ? "Required" : "";
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    };



    handleSubmit(event) {
        document.getElementById("submitValidateCard").disabled = true;

        event.preventDefault();
        iframe.tokenize();

        //wait for iframe to tokenize
        setTimeout(() => {
            let token = document.getElementById("token").value;
            let cardNumber = document.getElementById("cardNumber").value;
            let query = useQuery();
            this.setState({ contactId: query.get('ContactId') });
            if (token) {
                this.setState({ showMain: false });
                this.setState({ showTokenFailed: false });
                this.setState({ tokenExValue: token });

                fetch(this.state.suburl+'/TokenExIFrameConfig/PostExisting', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tokenEx_token: token,
                        card_number: cardNumber,
                        card_brand: query.get('CardBrand'),
                        contact_id: query.get('ContactId'),
                        amount: parseFloat(query.get('amount')).toFixed(2),
                        action_name: query.get('ActionName'),
                        user: query.get('User')
                    })

                }).then((response) => response.json())
                    .then((responseData) => {

                        if (responseData.message == "failed") {

                            this.setState({ showMain: true });
                            this.setState({ showTokenFailed: true });
                            this.setState({ result: responseData.result });
                            document.getElementById("submitValidateCard").disabled = false;
                        }
                        else {
                            this.setState({ date: responseData.date })
                            this.setState({ reference: responseData.reference })
                            this.setState({ name: responseData.name })
                            this.setState({ last4OfCard: responseData.last4OfCard })
                            this.setState({ authCode: responseData.authCode })
                            this.setState({ result: responseData.result })
                            this.setState({ totalAmount: responseData.totalAmount })
                            this.setState({ cardToken: responseData.cardToken })
                            this.setState({ email_id: responseData.email })
                            this.setState({ loanId: responseData.loanId })
                            this.setState({
                                printUrl: '/TokenExIFrameConfig/PdfGen?amount=' + responseData.totalAmount + '&loanId=' + this.state.loanId + '&reference=' + responseData.reference
                            })
                            this.setState({ showTokenSuceess: true })
                            this.setState({ showMailConfirmation: true })
                            this.setState({ showTokenFailed: false });


                            history.push('?status=success')
                        }
                    })
            }
            else {
                document.getElementById("submitValidateCard").disabled = false;
            }
        }, 1000);

    }
    handleSend(event) {
        event.preventDefault();

        iframe.tokenize();
        if (this.state.email_id !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.email_id)) {

                alert("Please enter valid email address.");
                return;
            }

        }

        //wait for iframe to tokenize
        setTimeout(() => {

            fetch(this.state.suburl +'/TokenExIFrameConfig/SendPaymentEmailConfirmation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    recipient: this.state.email_id,
                    date: this.state.date,
                    amount: this.state.totalAmount,
                    reference: this.state.reference,
                    contactId: this.state.contactId,
                    loanId: this.state.loanId
                })
            }).then((response) => response.json())
                .then((responseData) => {

                    if (responseData.message == "failed") {
                        alert("Payment Confirmation Sending Failed");
                    }
                    else {
                        alert("Payment Confirmation Sent");
                    }
                })

        }, 1000);

    }
    handlePrint(event) {
        event.preventDefault();
        setTimeout(() => {
            window.open(this.state.printUrl);
        }, 1000);
    }

    componentDidMount() {

        let query = useQuery();
        this.setState({ accountNumber: query.get('AccountNumber') });
        if (query.get('amount') != null && parseFloat(query.get('amount')) > 0)
            this.setState({ payAmount: "$" + parseFloat(query.get('amount')).toFixed(2) });
        else {
            this.setState({ payAmount: "$0.00" });
        }
        this.setState({ isDisable: false });

        //create a new instance of the iframe, and add the container ID and config object
        iframe = new this.props.TokenEx.Iframe("CvvTextbox", this.props.IFrameConfig);

        iframe.on("tokenize", function (data) {

            document.getElementById("token").value = data.token;
            document.getElementById("cardNumber").value = data.token.substring(0, 6) + "******" + data.token.substring(data.token.length - 4);
        });
        iframe.on("error", function (data) {
            //log error 
        });

        //calling the iframe's load function adds the iframe to the DOM.
        iframe.load();
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="container my-5">
                <div className="row my-1">
                    <div className="col-md-6 col-sm-12 mx-auto text-center">
                        <img src={cashstoreLogo} alt="Logo">
                        </img>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-md-6 col-sm-12 offset-md-3">
                        <div style={{ display: this.state.showMain ? 'block' : 'none' }}>
                            <form noValidate onSubmit={this.handleSubmit}>
                                <div className="card ">
                                    <div className="card-body px-1">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="alert alert-info text-center">You are paying with a {this.props.IFrameConfig.cardType.toUpperCase()} ending in {this.props.IFrameConfig.token.substr(this.props.IFrameConfig.token.length - 4)}</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Account Number*</label>&nbsp;
                                    {errors.account_number.length > 0 && (
                                                    <span className="text-danger">{errors.account_number}</span>
                                                )}
                                                <input type="text" maxLength="5" id="account_number" name="account_number" value={this.state.accountNumber} disabled="true" className="form-control" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>CVV*</label>&nbsp;
                                                <i onClick={this.handleCVVMask} class="eye_icon fas fa-eye-slash" id="toggleCVV"></i>
                                                <div id="CvvTextbox" style={{ height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ height: '10px', display: this.state.showTokenFailed ? 'block' : 'none' }}>
                                            <div className="form-group">
                                                <span className="h6 text-danger">{this.state.result}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ height: '185px' }}>
                                        <div className="form-group">
                                            <div className="h6">Please read the following statement to the customer in its entirety.</div>
                                            <br />
                                            <div>Do you allow the Cash Store to process a debit card transaction in the amount of {this.state.payAmount}, on {currentDate}?</div>
                                            <br />
                                            <div className="h6 text-danger">Please say "I agree" to authorize this transaction.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                        <div className="form-group text-center">
                                            <input type="submit" id="submitValidateCard" value={"Pay " + this.state.payAmount} className={this.state.isDisable === true ? "btn btn-secondary disabled" : "btn btn-success"} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row" style={{ display: this.state.showTokenSuceess ? 'block' : 'none' }}>
                            <div className="alert alert-success" role="alert">
                                {/*  <h4 className="alert-heading">Success!</h4>*/}
                                <input type="hidden" id="token" />
                                <input type="hidden" id="cardBrand" />
                                <input type="hidden" id="cardNumber" />
                                {/* <label>{this.state.tokenExValue}</label>*/}
                                <label>Thank you for your payment!</label>
                            </div>
                            <p className="h4 authsuccessheader">Payment Details</p>
                            <div className="row my-3">
                                <div className="col">
                                    <form className="sucessform">
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Date</label>

                                            <label className="col-sm-6 col-form-label">{this.state.date}</ label >

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Reference #</label>

                                            <label className="col-sm-6 col-form-label">{this.state.reference}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Name</label>

                                            <label className="col-sm-6 col-form-label">{this.state.name}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Last 4 of Card</label>

                                            <label className="col-sm-6 col-form-label">{this.state.last4OfCard}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Auth Code</label>

                                            <label className="col-sm-6 col-form-label">{this.state.authCode}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Result</label>

                                            <label className="col-sm-6 col-form-label">{this.state.result}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Total Amount</label>

                                            <label className="col-sm-6 col-form-label">{this.state.totalAmount}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Card Token</label>

                                            <label className="col-sm-6 col-form-label">{this.state.cardToken}</label>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        {/*<div className="row" style={{ display: this.state.showTokenFailed ? 'block' : 'none' }}>*/}
                        {/*    <div className="errorContainer">*/}
                        {/*        <p className="h5 text-danger">We are unable to process your request at this time.</p>*/}
                        {/*        <p className="h5 text-danger"> Please try again later.</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row" style={{ display: (this.state.showMain == false && this.state.showTokenSuceess == false && this.state.showTokenFailed == false && this.state.showMailConfirmation == false) ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center" style={{ height: '300px' }} >
                                <div className="spinner-border text-primary center" style={{ marginTop: '150px' }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: this.state.showMailConfirmation ? 'block' : 'none' }}>
                            <p className="h4 authsuccessheader">Send Payment Confirmation</p>
                            <form noValidate onSubmit={this.handleSend}>
                                <div className="card ">
                                    <div className="card-body px-1">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Email</label>&nbsp;
                                    {errors.email_id.length > 0 && (
                                                    <span className="text-danger">{errors.email_id}</span>
                                                )}
                                                <input type="text" id="email_id" name="email_id" value={this.state.email_id} className="form-control" onChange={this.handleChange} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer text-muted">
                                        <div className="form-group text-center">
                                            <input type="submit" id="submitSendMail" value="Send" className="btn btn-success" />
                                            <button onClick={this.handlePrint} className="btnPrint btn btn-success">Print</button>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >
            </div>
        );
    }
}

function useQuery() {
    return new URLSearchParams(window.location.search);
}
