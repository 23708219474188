import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Repay } from './components/Repay';
import { RepayCVVOnly } from './components/RepayCVVOnly';
import { PayByPhone } from './components/PayByPhone';
import { PayByPhoneCVVOnly } from './components/PayByPhoneCVVOnly';
import './bootstrap.min.css';
import { Footer } from './components/Footer';
import History from './components/History';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ erro: error, errorInfo: errorInfo })
    }
    render() {
        if (this.state.errorInfo) {
            return (
                <div class="errorContainer">
                    <p class="h5 text-danger">We are unable to process your request at this time.</p>
                    <p class="h5 text-danger"> Please try again later.</p>
                    {/*<details open style={{ marginLeft: '20px', whiteSpace: 'pre-wrap' }}>*/}
                    {/*    {this.state.error && this.state.error.toString()}*/}
                    {/*    <br />*/}
                    {/*    {this.state.errorInfo.componentStack}*/}
                    {/*</details>*/}
                </div>
            );
        }

        return this.props.children;
    }
}

export default class App extends Component {
     
    constructor(props) {
        super(props);
        let query = useQuery();
        let actionname = query.get('ActionName');
        let url = process.env.REACT_APP_SUB_URL;
        this.state = {
            iframeConfig: {},
            isContentLoaded: false,
            iscustomerportal: actionname == 'customerportal' ? true : false,
            suburl:url==undefined ?'':url
        };
       
    }

    loadCardWithCVVConfiguration() {
       
        const apiUrl = this.state.suburl+ '/TokenExIFrameConfig';
        const header = {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-store, no-cache, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 0
            }
        };

        fetch(apiUrl, header)
            .then((response) => response.json())
            .then((data) => {
                this.setState(
                    {
                        iframeConfig:
                        {
                            origin: data.origin,
                            timestamp: data.timestamp,
                            tokenExID: data.tokenExID,
                            authenticationKey: data.authenticationKey,
                            tokenScheme: data.tokenScheme,
                            pci: true,
                            cvv: true,
                            maskInput: data.maskInput,
                            cvvContainerID: "CvvTextbox",
                            styles: {
                                base: "font-family: Arial, Helvetica, sans-serif;padding: 0 8px;border: 1px solid rgba(0, 0, 0, 0.2);margin: 0;width: 100%;font-size: 15px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                                focus: "box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;",
                                error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);",
                                cvv: {
                                    base: "font-family: Arial, Helvetica, sans-serif;padding: 0 8px;border: 1px solid rgba(0, 0, 0, 0.2);margin: 0;width: 100%;font-size: 15px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                                    focus: "box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;",
                                    error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
                                }
                            }
                        },
                        isContentLoaded: true
                    });
            });
    }


    loadCVVOnlyConfiguration(token, cardType) {

        const apiUrl = this.state.suburl+'/TokenExIFrameConfig/CVVOnlyConfig';
        fetch(apiUrl,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store, no-cache, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': 0
                },
                body: JSON.stringify({
                    Token: token,
                    CardType: cardType
                })
            })
            .then((response) => response.json())
            .then((data) => {
                this.setState(
                    {
                        iframeConfig:
                        {
                            origin: data.origin,
                            timestamp: data.timestamp,
                            tokenExID: data.tokenExID,
                            authenticationKey: data.authenticationKey,
                            tokenScheme: data.tokenScheme,
                            cvv: true,
                            cvvOnly: true,
                            token: data.token,
                            cardType: data.cardType,
                            maskInput: data.maskInput,
                            cvvContainerID: "CvvTextbox",
                            styles: {
                                base: "font-family: Arial, Helvetica, sans-serif;padding: 0 8px;border: 1px solid rgba(0, 0, 0, 0.2);margin: 0;width: 100%;font-size: 15px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                                focus: "box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;",
                                error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);",
                                cvv: {
                                    base: "font-family: Arial, Helvetica, sans-serif;padding: 0 8px;border: 1px solid rgba(0, 0, 0, 0.2);margin: 0;width: 100%;font-size: 15px;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;",
                                    focus: "box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);border: 1px solid rgba(0, 132, 255, 0.5);outline: 0;",
                                    error: "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);border: 1px solid rgba(224, 57, 57, 0.5);"
                                }
                            }
                        },
                        isContentLoaded: true
                    });
            });
    }

    GetCreditCardType(CreditCardNumber) {

        let regVisa = new RegExp("^4[0-9]{0,}$");
        let regJCB = new RegExp("^(?:2131|1800|35)[0-9]{0,}$");
        let regMaster = new RegExp("^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$");
        let regExpress = new RegExp("^3[47][0-9]{0,}$");
        let regDiscover = new RegExp("^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$");
        let regDiners = new RegExp("^3(?:0[0-59]{1}|[689])[0-9]{0,}$");

        if (regVisa.test(CreditCardNumber))
            return "visa";
        else if (regMaster.test(CreditCardNumber))
            return "masterCard";
        else if (regExpress.test(CreditCardNumber))
            return "americanExpress";
        else if (regDiscover.test(CreditCardNumber))
            return "discover";
        else if (regDiners.test(CreditCardNumber))
            return "diners";
        else if (regJCB.test(CreditCardNumber))
            return "jcb";
        else
            return "NA";
    };

    componentDidMount() {

        let path = window.location.href.toString().replace(window.location.origin.toString(), "");
        let actions = path.split('/');
        if ((this.state.suburl == "" && actions[1].toLowerCase().includes("cvvonly")) || (this.state.suburl != "" && actions[2].toLowerCase().includes("cvvonly")))
        {

            let query = useQuery();
            this.loadCVVOnlyConfiguration(query.get('TokenExToken'), query.get('CardBrand'));
        }
        else {
            this.loadCardWithCVVConfiguration();
        }
    }

    render() {
        return (
            <div>
                <ErrorBoundary>
                    <Router History={History}>
                        <div>
                            <Route exact path={this.state.suburl !== "" ? this.state.suburl:"/"} >
                                {this.state.isContentLoaded && (<Repay TokenEx={this.props.TokenEx} IFrameConfig={this.state.iframeConfig} />)}
                            </Route>
                            <Route path={this.state.suburl !== "" ? this.state.suburl + "/paybyphone" : "/paybyphone"}>
                                {this.state.isContentLoaded && (<PayByPhone SubURL={this.state.suburl} TokenEx={this.props.TokenEx} IFrameConfig={this.state.iframeConfig} />)}
                            </Route>
                            <Route path={this.state.suburl !== "" ? this.state.suburl + "/repaycvvonly" :"/repaycvvonly"}>
                                {this.state.isContentLoaded && (<RepayCVVOnly TokenEx={this.props.TokenEx} IFrameConfig={this.state.iframeConfig} />)}
                            </Route>
                            <Route path={this.state.suburl !== "" ? this.state.suburl + "/paybyphonecvvonly" : "/paybyphonecvvonly"}>
                                {this.state.isContentLoaded && (<PayByPhoneCVVOnly TokenEx={this.props.TokenEx} IFrameConfig={this.state.iframeConfig} />)}
                            </Route>
                        </div>
                    </Router>
                </ErrorBoundary>
                {!this.state.iscustomerportal && <Footer />}
            </div>
        );
    }
}

function useQuery() {
    return new URLSearchParams(window.location.search);
}