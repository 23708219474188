import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

fetch(window.location.origin + '/TokenExIFrameConfig/ClientConfig').then(async (response) => {
    response.json().then((config) => {
        for (const entry in config) {
            process.env[entry.toUpperCase()] = config[entry];
            console.log(`Applying setting: ${entry.toUpperCase()} = ${config[entry]}`)
        }
        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
        const rootElement = document.getElementById('root');
        ReactDOM.render(
            <BrowserRouter basename={process.env.REACT_APP_SUB_URL}>
                <App TokenEx={window.TokenEx} />
            </BrowserRouter>,
            rootElement);

        registerServiceWorker();
    });
});
