import React, { Component } from 'react';
import history from './History'
import './Main.css'
import cashstoreLogo from './Images/cash-store-main.png'
import visaLogo from './Images/visa_icon.gif'
import mastercardLogo from './Images/mastercard.png'
import discoverLogo from './Images/DiscoverLogo.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const icons = require("@fortawesome/free-solid-svg-icons");
let iframe;
let isValidCardholder_name = false;
let isValidAddress_zip = false;
let isValidCard_expiration_month = false;
let isValidCard_expiration_year = false;
let monthOptions = [];
let isValidToken = false;
let isValidCard = false;
let currentYear = (new Date()).getFullYear() % 100;
let currentMonth = (new Date()).getMonth() + 1;

export class Repay extends Component {
    static displayName = Repay.name;

    constructor(props) {
        super(props);
        let url = process.env.REACT_APP_SUB_URL;
        const year = (new Date()).getFullYear() % 100;
        this.years = Array.from(new Array(15), (val, index) => index + year);

        for (let i = 1; i <= 12; i++) {
            let month = ("0" + (i)).slice(-2);
            monthOptions.push(<option key={`month${month}`} value={month}>{month}</option>);
        }


        this.handleSubmit = this.handleSubmit.bind(this);


        let query = useQuery();
        let actionname = query.get('ActionName');
        console.log(actionname);
        if (actionname == 'customerportal') {
            this.state = {
                cardholder_name: null,
                card_number: null,
                address: null,
                address_zip: null,
                card_cvv: null,
                card_expiration_month: null,
                card_expiration_year: null,
                showTokenSuceess: false,
                showTokenFailed: false,
                showMain: true,
                tokenExValue: null,
                isDisable: true,
                date: "",
                reference: "",
                name: "",
                last4OfCard: "",
                authCode: "",
                result: "",
                totalAmount: "",
                cardToken: "",
                action_name: "",
                cashstorelogo: false,
                cashstorefooter: true,
                iscustomerportal:true,
                errors: {
                    cardholder_name: "",
                    card_number: "",
                    address: "",
                    address_zip: "",
                    card_cvv: "",
                    card_expiration_month: "",
                    card_expiration_year: ""
                },
                suburl: url == undefined ? '' : url
            };

        }
        else {
            this.state = {
                cardholder_name: null,
                card_number: null,
                address: null,
                address_zip: null,
                card_cvv: null,
                card_expiration_month: null,
                card_expiration_year: null,
                showTokenSuceess: false,
                showTokenFailed: false,
                showMain: true,
                tokenExValue: null,
                isDisable: true,
                date: "",
                reference: "",
                name: "",
                last4OfCard: "",
                authCode: "",
                result: "",
                totalAmount: "",
                cardToken: "",
                action_name: "",
                cashstorelogo: true,
                cashstorefooter: true,
                iscustomerportal: false,
                errors: {
                    cardholder_name: "",
                    card_number: "",
                    address: "",
                    address_zip: "",
                    card_cvv: "",
                    card_expiration_month: "",
                    card_expiration_year: ""
                },
                suburl: url == undefined ? '' : url
            };
        }

    }

    handleCardMask = event => {

        if (document.getElementById("toggleCardNumber").classList.contains('fa-eye-slash')) {
            document.getElementById("toggleCardNumber").classList.remove('fa-eye-slash');
            document.getElementById("toggleCardNumber").classList.add('fa-eye');
        }
        else {
            document.getElementById("toggleCardNumber").classList.remove('fa-eye');
            document.getElementById("toggleCardNumber").classList.add('fa-eye-slash');
        }

        iframe.toggleMask();
    }

    handleCVVMask = event => {

        if (document.getElementById("toggleCVV").classList.contains('fa-eye-slash')) {
            document.getElementById("toggleCVV").classList.remove('fa-eye-slash');
            document.getElementById("toggleCVV").classList.add('fa-eye');
        }
        else {
            document.getElementById("toggleCVV").classList.remove('fa-eye');
            document.getElementById("toggleCVV").classList.add('fa-eye-slash');
        }

        iframe.toggleCvvMask();
    }

    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case "cardholder_name":
                if (value.length === 0) {
                    isValidCardholder_name = false;
                }
                else {
                    isValidCardholder_name = true;
                }
                errors.cardholder_name =
                    value.length === 0 ? "Required" : "";
                break;
            case "address_zip":
                if (value.length === 0) {
                    errors.address_zip = "Required";
                    isValidAddress_zip = false;
                }
                else if (value.length !== 5 || !Number(value)) {
                    errors.address_zip = "Invalid Zipcode";
                    isValidAddress_zip = false;
                }
                else {
                    errors.address_zip = "";
                    isValidAddress_zip = true;
                }

                break;
            case "card_expiration_month":
                if (value === "MM") {
                    isValidCard_expiration_month = false;
                    errors.card_expiration_month = "Required";
                }
                else {

                    if (this.state.card_expiration_year !== null
                        && this.state.card_expiration_year !== "YY"
                        && parseInt(this.state.card_expiration_year) === currentYear
                        && parseInt(value) < currentMonth) {
                        isValidCard_expiration_month = false;
                        errors.card_expiration_month = "Invalid Date";
                    }
                    else {
                        isValidCard_expiration_month = true;
                        errors.card_expiration_month = "";
                    }

                }

                break;
            case "card_expiration_year":
                if (value === "YY") {
                    isValidCard_expiration_year = false;
                }
                else {
                    if (parseInt(value) === currentYear
                        && parseInt(this.state.card_expiration_month) < currentMonth) {
                        isValidCard_expiration_month = false;
                        errors.card_expiration_month = "Invalid Date";
                    }
                    else {
                        if (parseInt(value) > currentYear && this.state.card_expiration_month !== "MM") {
                            isValidCard_expiration_month = true;
                            errors.card_expiration_month = "";
                        }

                        isValidCard_expiration_year = true;

                    }

                }
                errors.card_expiration_year =
                    (value === "YY" && errors.card_expiration_month === "") ? "Required" : "";
                break;
            default:
                break;
        }

        if (isValidCardholder_name
            && isValidAddress_zip
            && isValidCard_expiration_month
            && isValidCard_expiration_year) {
            this.setState({ isDisable: false });
        }
        else {
            this.setState({ isDisable: true });
        }

        this.setState({ errors, [name]: value });
    };



    handleSubmit(event) {
        document.getElementById("submitValidateCard").disabled = true;
      
        event.preventDefault();

        if (this.state.isDisable) {
            return;
        }

        iframe.tokenize();

        //wait for iframe to tokenize
        setTimeout(() => {
            let token = document.getElementById("token").value;
            let cardBrand = document.getElementById("cardBrand").value;
            let cardNumber = document.getElementById("cardNumber").value;
            let query = useQuery();

            if (isValidCard) {
                if (isValidToken) {

                    //  this.setState({ showTokenSuceess: true });
                    this.setState({ showMain: false });
                    this.setState({ showTokenFailed: false });
                    this.setState({ tokenExValue: token });

                    fetch(this.state.suburl+'/TokenExIFrameConfig', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            cardholder_name: this.state.cardholder_name,
                            tokenEx_token: token,
                            card_number: cardNumber,
                            card_brand: cardBrand,
                            address: this.state.address,
                            address_zip: this.state.address_zip,
                            card_expiration: this.state.card_expiration_month + this.state.card_expiration_year,
                            contact_id: query.get('ContactId'),
                            action_name: query.get('ActionName')
                        })

                    }).then((response) => response.json())
                        .then((responseData) => {

                            if (responseData.message == "failed") {
                                this.setState({ showMain: true });
                                this.setState({ showTokenFailed: true });
                                this.setState({ result: responseData.result });
                                document.getElementById("submitValidateCard").disabled = false;
                            }
                            else if (responseData.message == "Success") {
                                this.setState({ date: responseData.date })
                                this.setState({ reference: responseData.reference })
                                this.setState({ name: responseData.name })
                                this.setState({ last4OfCard: responseData.last4OfCard })
                                this.setState({ authCode: responseData.authCode })
                                this.setState({ result: responseData.result })
                                this.setState({ totalAmount: responseData.totalAmount })
                                this.setState({ cardToken: responseData.cardToken })
                                this.setState({ showTokenSuceess: true })


                                history.push('?status=success')
                            }
                            else {
                                //this is the customerportal response
                                window.top.postMessage(
                                    responseData,
                                    '*'
                                );
                            }
                        })

                }
                else {
                    this.setState({ showTokenFailed: true });
                    this.setState({ showMain: false });
                    this.setState({ tokenExValue: token });
                    history.push('?status=failed')

                }
            }
            else {
                document.getElementById("submitValidateCard").disabled = false;
            }

        }, 3000);

    }

    componentDidMount() {
        //create a new instance of the iframe, and add the container ID and config object
        iframe = new this.props.TokenEx.Iframe("tokenExIframeDiv", this.props.IFrameConfig);

        iframe.on("validate", function (data) {
            isValidCard = data.isValid == true && data.isCvvValid == true;
            console.log("CC iFrame validate:" + JSON.stringify(data));
        });

        iframe.on("tokenize", function (data) {

            fetch((process.env.REACT_APP_SUB_URL == undefined ? '' : process.env.REACT_APP_SUB_URL) +'/TokenExIFrameConfig/ValidateToken',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        Token: data.token,
                        TokenHMAC: data.tokenHMAC
                    })
                })
                .then((response) => response.json())
                .then((result) => {
                    isValidToken = result.valid;
                    if (result.valid) {
                        document.getElementById("token").value = data.token;
                        document.getElementById("cardBrand").value = data.cardType;
                        document.getElementById("cardNumber").value = data.firstSix + "******" + data.lastFour;
                    }
                    else {
                        document.getElementById("token").value = "Invalid Token";
                    }

                });

        });
        iframe.on("error", function (data) {
            //log error 
        });

        //calling the iframe's load function adds the iframe to the DOM.
        iframe.load();

    }

    render() {
        const { errors } = this.state;
        return (
        <>
            {this.state.iscustomerportal && <div className="container my-5">
                
                <div className="row">
                        <div className="col-md-6 col-sm-12 mx-auto my-2">
                            <div className="text-right" style={{ marginRight:23 }}>
                            <img src={visaLogo} alt="Visa" height="30px" />
                            <img src={mastercardLogo} alt="Master" height="30px" style={{ marginLeft: '5px' }} />
                            <img src={discoverLogo} alt="Discover" height="30px" style={{ marginLeft: '5px' }} />
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-6 col-sm-12 offset-md-3">
                        <div style={{ display: this.state.showMain ? 'block' : 'none' }}>
                                <form noValidate onSubmit={this.handleSubmit}>
                                    <div className="card " style={{ border:'none' }}>
                                        <div className="card-body px-1">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Cardholder Name</label>&nbsp;
                                    {errors.cardholder_name.length > 0 && (
                                                        <span className="text-danger">{errors.cardholder_name}</span>
                                                    )}
                                                    <input type="text" id="cardholder_name" name="cardholder_name" className="form-control" onChange={this.handleChange} noValidate />

                                                </div>
                                            </div>
                                      
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                <i onClick={this.handleCardMask} class="eye_icon fas fa-eye-slash" id="toggleCardNumber"></i>
                                                <div id="tokenExIframeDiv" style={{ height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                    <label>CVV  <FontAwesomeIcon title="CVV is the security code on the back of your card." style={{width:13}} className="my-icon" icon={icons["faInfoCircle"]} />
</label>&nbsp;
                                       <i onClick={this.handleCVVMask} class="eye_icon fas fa-eye-slash" id="toggleCVV"></i>
                                                <div id="CvvTextbox" style={{ height: '40px' }} />

                                            </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Zip Code</label>&nbsp;
                                    {errors.address_zip.length > 0 && (
                                                        <span className="text-danger">{errors.address_zip}</span>
                                                    )}
                                                    <input type="text" maxLength="5" id="address_zip" name="address_zip" className="form-control" onChange={this.handleChange} />
                                                </div>
                                            </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Expiration Date</label>
                                            &nbsp;
                                    {errors.card_expiration_month.length > 0 && (
                                                    <span className="text-danger">{errors.card_expiration_month}</span>
                                                )}
                                                {errors.card_expiration_year.length > 0 && (
                                                    <span className="text-danger">{errors.card_expiration_year}</span>
                                                )}
                                                    <div className="row">
                                                        <div className="col-4" style={{ minWidth:110 }}>
                                                        <select id="card_expiration_month" name="card_expiration_month" className="form-control" onChange={this.handleChange}>
                                                            <option>MM</option>
                                                            {monthOptions}
                                                        </select>
                                                    </div>
                                                        <div className="col-4" style={{ minWidth: 110 }}>
                                                        <select id="card_expiration_year" name="card_expiration_year" className="form-control" onChange={this.handleChange}>
                                                            <option>YY</option>
                                                            {
                                                                this.years.map((year, index) => {
                                                                    return <option key={`year${index}`} value={year}>{year}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ height: '10px', display: this.state.showTokenFailed ? 'block' : 'none' }}>
                                            <div className="form-group">
                                                <span className="h6 text-danger">{this.state.result}</span>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                            <div className="form-group text-center">
                                                <input type="submit" id="submitValidateCard" style={{ backgroundColor:'#5558af' }} value="Confirm Payment" className={this.state.isDisable === true ? "btn btn-secondary disabled" : "btn btn-success"} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row" style={{ display: this.state.showTokenSuceess ? 'block' : 'none' }}>
                            <div className="alert alert-success" role="alert">
                                {/*  <h4 className="alert-heading">Success!</h4>*/}
                                <input type="hidden" id="token" />
                                <input type="hidden" id="cardBrand" />
                                <input type="hidden" id="cardNumber" />
                                {/* <label>{this.state.tokenExValue}</label>*/}
                                <label>Thank you for your authorization!</label>
                            </div>
                            <p className="h4 authsuccessheader">Authorization Details</p>
                            <div className="row my-3">
                                <div className="col">
                                    <form className="sucessform">
                                        <div className="form-group row " class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Date</label>

                                            <label className="col-sm-6 col-form-label">{this.state.date}</ label >

                                        </div>
                                        <div className="form-group row " class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Reference #</label>

                                            <label className="col-sm-6 col-form-label">{this.state.reference}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Name</label>

                                            <label className="col-sm-6 col-form-label">{this.state.name}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Last 4 of Card</label>

                                            <label className="col-sm-6 col-form-label">{this.state.last4OfCard}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Auth Code</label>

                                            <label className="col-sm-6 col-form-label">{this.state.authCode}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Result</label>

                                            <label className="col-sm-6 col-form-label">{this.state.result}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Total Amount</label>

                                            <label className="col-sm-6 col-form-label">{this.state.totalAmount}</label>

                                        </div>
                                        <div className="form-group row" class="formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Card Token</label>

                                            <label className="col-sm-6 col-form-label">{this.state.cardToken}</label>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.showMain == false && this.state.showTokenSuceess == false && this.state.showTokenFailed == false) ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center" style={{ height: '300px' }} >
                                <div className="spinner-border text-primary center" style={{ marginTop: '150px' }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                </div>}
                { !this.state.iscustomerportal && <div className="container my-5">
                    <div className="row my-1" style={{ display: this.state.cashstorelogo ? 'block' : 'none' }}>
                        <div className="col-md-6 col-sm-12 mx-auto text-center">
                            <img src={cashstoreLogo} alt="Logo">
                            </img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mx-auto my-2">
                            <div className="text-right">
                                <img src={visaLogo} alt="Visa" height="30px" />
                                <img src={mastercardLogo} alt="Master" height="30px" style={{ marginLeft: '5px' }} />
                                <img src={discoverLogo} alt="Discover" height="30px" style={{ marginLeft: '5px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-md-6 col-sm-12 offset-md-3">
                            <div style={{ display: this.state.showMain ? 'block' : 'none' }}>
                                <form noValidate onSubmit={this.handleSubmit}>
                                    <div className="card ">
                                        <div className="card-body px-1">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Zip Code*</label>&nbsp;
                                    {errors.address_zip.length > 0 && (
                                                        <span className="text-danger">{errors.address_zip}</span>
                                                    )}
                                                    <input type="text" maxLength="5" id="address_zip" name="address_zip" className="form-control" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Cardholder Name*</label>&nbsp;
                                    {errors.cardholder_name.length > 0 && (
                                                        <span className="text-danger">{errors.cardholder_name}</span>
                                                    )}
                                                    <input type="text" id="cardholder_name" name="cardholder_name" className="form-control" onChange={this.handleChange} noValidate />

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Card Number*</label>
                                                    <i onClick={this.handleCardMask} class="eye_icon fas fa-eye-slash" id="toggleCardNumber"></i>
                                                    <div id="tokenExIframeDiv" style={{ height: '40px' }} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>CVV*</label>&nbsp;
                                                <i onClick={this.handleCVVMask} class="eye_icon fas fa-eye-slash" id="toggleCVV"></i>
                                                    <div id="CvvTextbox" style={{ height: '40px' }} />

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Expiration Date*</label>
                                            &nbsp;
                                    {errors.card_expiration_month.length > 0 && (
                                                        <span className="text-danger">{errors.card_expiration_month}</span>
                                                    )}
                                                    {errors.card_expiration_year.length > 0 && (
                                                        <span className="text-danger">{errors.card_expiration_year}</span>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <select id="card_expiration_month" name="card_expiration_month" className="form-control" onChange={this.handleChange}>
                                                                <option>MM</option>
                                                                {monthOptions}
                                                            </select>
                                                        </div>
                                                        <div className="col-4">
                                                            <select id="card_expiration_year" name="card_expiration_year" className="form-control" onChange={this.handleChange}>
                                                                <option>YY</option>
                                                                {
                                                                    this.years.map((year, index) => {
                                                                        return <option key={`year${index}`} value={year}>{year}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ height: '10px', display: this.state.showTokenFailed ? 'block' : 'none' }}>
                                                <div className="form-group">
                                                    <span className="h6 text-danger">{this.state.result}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-muted" style={{ display: this.state.cashstorefooter ? 'block' : 'none' }}>
                                            <div className="form-group text-center" style={{ height: '10px' }}>
                                                <label class="footertext">By proceeding, this card will be authorized for future use.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col">
                                            <div className="form-group text-center">
                                                <input type="submit" id="submitValidateCard" value="Authorize" className={this.state.isDisable === true ? "btn btn-secondary disabled" : "btn btn-success"} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="row" style={{ display: this.state.showTokenSuceess ? 'block' : 'none' }}>
                                <div className="alert alert-success" role="alert">
                                    {/*  <h4 className="alert-heading">Success!</h4>*/}
                                    <input type="hidden" id="token" />
                                    <input type="hidden" id="cardBrand" />
                                    <input type="hidden" id="cardNumber" />
                                    {/* <label>{this.state.tokenExValue}</label>*/}
                                    <label>Thank you for your authorization!</label>
                                </div>
                                <p className="h4 authsuccessheader">Authorization Details</p>
                                <div className="row my-3">
                                    <div className="col">
                                        <form className="sucessform">
                                            <div className="form-group row " class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Date</label>

                                                <label className="col-sm-6 col-form-label">{this.state.date}</ label >

                                            </div>
                                            <div className="form-group row " class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Reference #</label>

                                                <label className="col-sm-6 col-form-label">{this.state.reference}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Name</label>

                                                <label className="col-sm-6 col-form-label">{this.state.name}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Last 4 of Card</label>

                                                <label className="col-sm-6 col-form-label">{this.state.last4OfCard}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Auth Code</label>

                                                <label className="col-sm-6 col-form-label">{this.state.authCode}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Result</label>

                                                <label className="col-sm-6 col-form-label">{this.state.result}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Total Amount</label>

                                                <label className="col-sm-6 col-form-label">{this.state.totalAmount}</label>

                                            </div>
                                            <div className="form-group row" class="formdivSucess" >
                                                <label className="col-sm-6 col-form-label">Card Token</label>

                                                <label className="col-sm-6 col-form-label">{this.state.cardToken}</label>

                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>

                            <div className="row" style={{ display: (this.state.showMain == false && this.state.showTokenSuceess == false && this.state.showTokenFailed == false) ? 'block' : 'none' }}>
                                <div className="d-flex justify-content-center" style={{ height: '300px' }} >
                                    <div className="spinner-border text-primary center" style={{ marginTop: '150px' }} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>}
             </>
        );
    }
}
function useQuery() {
    return new URLSearchParams(window.location.search);
}