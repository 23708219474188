import React, { Component } from 'react';
import { MDBContainer, MDBFooter } from "mdbreact";


export class Footer extends Component {

    render() {
        return (
            <MDBFooter color="blue" id="footerlogo" className="font-small ">
                <div className="footer-copyright text-center">
                    <MDBContainer fluid>
                        &copy; {new Date().getFullYear()} Copyright: <a href=""> Cottonwood Financial Ltd </a>
                    </MDBContainer>
                </div>
            </MDBFooter>
        );
    }
}


