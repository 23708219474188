import React, { Component } from 'react';
import history from './History'
import './Main.css'
import cashstoreLogo from './Images/cash-store-main.png'

let iframe;
let isValidaccount_number = false;

export class RepayCVVOnly extends Component {
    static displayName = RepayCVVOnly.name;

    constructor(props) {
        super(props);
        let url = process.env.REACT_APP_SUB_URL;
        this.state = {

            account_number: null,
            card_cvv: null,
            showTokenSuceess: false,
            showMain: true,
            tokenExValue: null,
            isDisable: true,
            accountNumber: null,
            date: "",
            reference: "",
            name: "",
            last4OfCard: "",
            authCode: "",
            result: "",
            totalAmount: "",
            cardToken: "",
            errors: {
                account_number: "",
                card_cvv: ""
            },
            suburl: url == undefined ? '' : url
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCVVMask = event => {

        if (document.getElementById("toggleCVV").classList.contains('fa-eye-slash')) {
            document.getElementById("toggleCVV").classList.remove('fa-eye-slash');
            document.getElementById("toggleCVV").classList.add('fa-eye');
        }
        else {
            document.getElementById("toggleCVV").classList.remove('fa-eye');
            document.getElementById("toggleCVV").classList.add('fa-eye-slash');
        }

        iframe.toggleMask();
    }

    handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case "account_number":
                if (value.length === 0) {
                    isValidaccount_number = false;
                }
                else {
                    isValidaccount_number = true;
                }
                if (isValidaccount_number) {
                    this.setState({ isDisable: false });
                }
                else {
                    this.setState({ isDisable: true });
                }
                errors.account_number =
                    value.length === 0 ? "Required" : "";
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    };



    handleSubmit(event) {
        document.getElementById("submitValidateCard").disabled = true;

        event.preventDefault();
        iframe.tokenize();

        //wait for iframe to tokenize
        setTimeout(() => {
            let token = document.getElementById("token").value;
            let cardNumber = document.getElementById("cardNumber").value;
            let query = useQuery();
            if (token) {
                this.setState({ showMain: false });
                this.setState({ showTokenFailed: false });
                this.setState({ tokenExValue: token });

                fetch(this.state.suburl+'/TokenExIFrameConfig/PostExisting', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tokenEx_token: token,
                        card_number: cardNumber,
                        card_brand: query.get('CardBrand'),
                        contact_id: query.get('ContactId')
                    })

                }).then((response) => response.json())
                    .then((responseData) => {
                        console.log(responseData);
                        if (responseData.message == "failed") {
                            this.setState({ showMain: true });
                            this.setState({ showTokenFailed: true });
                            this.setState({ result: responseData.result });
                            document.getElementById("submitValidateCard").disabled = false;
                        }
                        else {
                            this.setState({ date: responseData.date })
                            this.setState({ reference: responseData.reference })
                            this.setState({ name: responseData.name })
                            this.setState({ last4OfCard: responseData.last4OfCard })
                            this.setState({ authCode: responseData.authCode })
                            this.setState({ result: responseData.result })
                            this.setState({ totalAmount: responseData.totalAmount })
                            this.setState({ cardToken: responseData.cardToken })
                            this.setState({ showTokenSuceess: true })
                            this.setState({ showTokenFailed: false });

                            history.push('?status=success')
                        }
                    })


            }
            else {
                document.getElementById("submitValidateCard").disabled = false;
            }
        }, 1000);

    }

    componentDidMount() {

        let query = useQuery();
        this.setState({ accountNumber: query.get('AccountNumber') });
        this.setState({ isDisable: false });

        //create a new instance of the iframe, and add the container ID and config object
        iframe = new this.props.TokenEx.Iframe("CvvTextbox", this.props.IFrameConfig);

        iframe.on("tokenize", function (data) {
            document.getElementById("token").value = data.token
            document.getElementById("cardNumber").value = data.token.substring(0, 6) + "******" + data.token.substring(data.token.length - 4);
        });
        iframe.on("error", function (data) {
            //log error 
        });

        //calling the iframe's load function adds the iframe to the DOM.
        iframe.load();
    }

    render() {
        const { errors } = this.state;
        return (
            <div className="container my-5">
                <div className="row my-1">
                    <div className="col-md-6 col-sm-12 mx-auto text-center">
                        <img src={cashstoreLogo} alt="Logo">
                        </img>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-md-6 col-sm-12 offset-md-3">
                        <div style={{ display: this.state.showMain ? 'block' : 'none' }}>
                            <form noValidate onSubmit={this.handleSubmit}>
                                <div className="card ">
                                    <div className="card-body px-1">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="alert alert-info text-center">You are paying with a {this.props.IFrameConfig.cardType.toUpperCase()} ending in {this.props.IFrameConfig.token.substr(this.props.IFrameConfig.token.length - 4)}</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Account Number*</label>&nbsp;
                                    {errors.account_number.length > 0 && (
                                                    <span className="text-danger">{errors.account_number}</span>
                                                )}
                                                <input type="text" maxLength="5" id="account_number" name="account_number" value={this.state.accountNumber} disabled="true" className="form-control" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>CVV*</label>&nbsp;
                                                <i onClick={this.handleCVVMask} class="eye_icon fas fa-eye-slash" id="toggleCVV"></i>
                                                <div id="CvvTextbox" style={{ height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ height: '10px', display: this.state.showTokenFailed ? 'block' : 'none' }}>
                                            <div className="form-group">
                                                <span className="h6 text-danger">{this.state.result}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer text-muted">
                                        <div className="form-group text-center" style={{ height: '10px' }}>
                                            <label>By proceeding, this card will be authorized for future use.</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3">
                                    <div className="col">
                                        <div className="form-group text-center">
                                            <input type="submit" id="submitValidateCard" value="Authorize" className={this.state.isDisable === true ? "btn btn-secondary disabled" : "btn btn-success"} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row" style={{ display: this.state.showTokenSuceess ? 'block' : 'none' }}>
                            <div className="alert alert-success" role="alert">
                                {/*  <h4 className="alert-heading">Success!</h4>*/}
                                <input type="hidden" id="token" />
                                <input type="hidden" id="cardBrand" />
                                <input type="hidden" id="cardNumber" />
                                {/* <label>{this.state.tokenExValue}</label>*/}
                                <label>Thank you for your authorization!</label>
                            </div>
                            <p className="h4 authsuccessheader">Authorization Details</p>
                            <div className="row my-3">
                                <div className="col">
                                    <form className="sucessform">
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Date</label>

                                            <label className="col-sm-6 col-form-label">{this.state.date}</ label >

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Reference #</label>

                                            <label className="col-sm-6 col-form-label">{this.state.reference}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Name</label>

                                            <label className="col-sm-6 col-form-label">{this.state.name}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Last 4 of Card</label>

                                            <label className="col-sm-6 col-form-label">{this.state.last4OfCard}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Auth Code</label>

                                            <label className="col-sm-6 col-form-label">{this.state.authCode}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Result</label>

                                            <label className="col-sm-6 col-form-label">{this.state.result}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Total Amount</label>

                                            <label className="col-sm-6 col-form-label">{this.state.totalAmount}</label>

                                        </div>
                                        <div className="form-group row formdivSucess" >
                                            <label className="col-sm-6 col-form-label">Card Token</label>

                                            <label className="col-sm-6 col-form-label">{this.state.cardToken}</label>

                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                        <div className="row" style={{ display: (this.state.showMain == false && this.state.showTokenSuceess == false && this.state.showTokenFailed == false) ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center" style={{ height: '300px' }} >
                                <div className="spinner-border text-primary center" style={{ marginTop: '150px' }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        );
    }
}

function useQuery() {
    return new URLSearchParams(window.location.search);
}
